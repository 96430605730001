body{
    background-color: #FFFFFF;
    font-family: 'Roboto', sans-serif;
}

.bg-imi-dark {
    background-color: #666666;
}

.site-header {
    margin-bottom: 0;
}

.container {
    width: 100%;
    padding-right: 0px;
    padding-left: 0px;
    margin-right: auto;
    margin-left: auto;
}

.top-header{
    border-bottom: 1px Solid #CCCCCC;
    color: #999999;
}

.top-header a, .top-header a:visited{
    color: #999999;
}
@media (max-width: 445px){
    .navbar-brand img {
        width: 240px;
    }
}

@media (max-width: 991.98px) {
    .home-header-search{
        display: none;
    }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
    .home-header-search{
        width: 240px;
    }
}

@media (min-width: 1200px) {
    .home-header-search{
        width: 420px;
    }
}


.form-inline .custom-select, .form-inline .input-group {
    width: inherit;
}

.navbar{
    box-shadow: none;
}

.navbar-brand img {
    height: inherit;
    display: block;
}
.navbar-collapse {
    flex-grow: unset;
}

.top-navbar{
    border-bottom: 1px Solid #cccccc;
    font-size: 0.8rem;
    color: #888888;
    padding-bottom: 5px;
}

.logo{
    padding: 0;
}

.bg-light {
    background-color: #FFFFFF !important;
}

.home .home-hero{
    padding: 0;
    margin: 0;
    background-color: #FFFFFF;
}

.home .home-hero > .container{
    text-align: right;
    color: #fff;
    background-image: url('../images/backgrounds/a-01.jpg');
    /* background-size: cover; */
    background-position: center center;
    background-repeat: no-repeat;
    /* background-attachment: fixed; */
    background-color: #FFFFFF;
    min-height: 338px;
    padding: 4.2rem 3rem;
}

.home .home-hero > .container a{
    color: white;
    border: 1px Solid White;
    border-radius: 0px;
}

.home .home-hero .lead {
    font-style: italic;
    margin-bottom: 2rem;
}

.top-navbar-social-icon{
    width: 20px;
}

.profile-hero{
    padding: 0;
    margin: 0;
    background-color: #FFFFFF;

}

.overCoverImage {
    width: 100%;
    color: #fff;
    position: relative;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    min-height: 338px;
    padding: 0;
}

.overCoverImage:before {
     content: '';
     position: absolute;
     top: 0;
     right: 0;
     bottom: 0;
     left: 0;
       background-image: rgba(0,0,0,0);
       background-image: -moz-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 30%, rgba(0,0,0,0.52) 54%, rgba(0,0,0,0.9) 89%, rgba(0,0,0,0.9) 100%);
       background-image: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(0,0,0,0)), color-stop(30%, rgba(0,0,0,0)), color-stop(54%, rgba(0,0,0,0.52)), color-stop(89%, rgba(0,0,0,0.9)), color-stop(100%, rgba(0,0,0,0.9)));
       background-image: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 30%, rgba(0,0,0,0.52) 54%, rgba(0,0,0,0.9) 89%, rgba(0,0,0,0.9) 100%);
       background-image: -o-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 30%, rgba(0,0,0,0.52) 54%, rgba(0,0,0,0.9) 89%, rgba(0,0,0,0.9) 100%);
       background-image: -ms-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 30%, rgba(0,0,0,0.52) 54%, rgba(0,0,0,0.9) 89%, rgba(0,0,0,0.9) 100%);
       background-image: linear-gradient(to bottom, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 30%, rgba(0,0,0,0.52) 54%, rgba(0,0,0,0.9) 89%, rgba(0,0,0,0.9) 100%);
       filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#000000', GradientType=0 );
}

.coverContent{
    height: 100%;
}

.overProjectCoverImage {
    width: 100%;
    color: #fff;
    position: relative;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    min-height: 250px;
    padding: 0;
}

.overProjectCoverImage:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(76,76,76,0);
    background: -moz-linear-gradient(top, rgba(76,76,76,0) 0%, rgba(89,89,89,0) 12%, rgba(102,102,102,0) 25%, rgba(71,71,71,0) 39%, rgba(44,44,44,0) 50%, rgba(0,0,0,0) 51%, rgba(17,17,17,0) 60%, rgba(19,19,19,0) 61%, rgba(43,43,43,0.64) 76%, rgba(40,40,40,0.77) 79%, rgba(28,28,28,0.86) 91%, rgba(26,26,26,0.88) 93%, rgba(25,25,25,0.88) 94%, rgba(36,36,36,0.88) 100%);
    background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(76,76,76,0)), color-stop(12%, rgba(89,89,89,0)), color-stop(25%, rgba(102,102,102,0)), color-stop(39%, rgba(71,71,71,0)), color-stop(50%, rgba(44,44,44,0)), color-stop(51%, rgba(0,0,0,0)), color-stop(60%, rgba(17,17,17,0)), color-stop(61%, rgba(19,19,19,0)), color-stop(76%, rgba(43,43,43,0.64)), color-stop(79%, rgba(40,40,40,0.77)), color-stop(91%, rgba(28,28,28,0.86)), color-stop(93%, rgba(26,26,26,0.88)), color-stop(94%, rgba(25,25,25,0.88)), color-stop(100%, rgba(36,36,36,0.88)));
    background: -webkit-linear-gradient(top, rgba(76,76,76,0) 0%, rgba(89,89,89,0) 12%, rgba(102,102,102,0) 25%, rgba(71,71,71,0) 39%, rgba(44,44,44,0) 50%, rgba(0,0,0,0) 51%, rgba(17,17,17,0) 60%, rgba(19,19,19,0) 61%, rgba(43,43,43,0.64) 76%, rgba(40,40,40,0.77) 79%, rgba(28,28,28,0.86) 91%, rgba(26,26,26,0.88) 93%, rgba(25,25,25,0.88) 94%, rgba(36,36,36,0.88) 100%);
    background: -o-linear-gradient(top, rgba(76,76,76,0) 0%, rgba(89,89,89,0) 12%, rgba(102,102,102,0) 25%, rgba(71,71,71,0) 39%, rgba(44,44,44,0) 50%, rgba(0,0,0,0) 51%, rgba(17,17,17,0) 60%, rgba(19,19,19,0) 61%, rgba(43,43,43,0.64) 76%, rgba(40,40,40,0.77) 79%, rgba(28,28,28,0.86) 91%, rgba(26,26,26,0.88) 93%, rgba(25,25,25,0.88) 94%, rgba(36,36,36,0.88) 100%);
    background: -ms-linear-gradient(top, rgba(76,76,76,0) 0%, rgba(89,89,89,0) 12%, rgba(102,102,102,0) 25%, rgba(71,71,71,0) 39%, rgba(44,44,44,0) 50%, rgba(0,0,0,0) 51%, rgba(17,17,17,0) 60%, rgba(19,19,19,0) 61%, rgba(43,43,43,0.64) 76%, rgba(40,40,40,0.77) 79%, rgba(28,28,28,0.86) 91%, rgba(26,26,26,0.88) 93%, rgba(25,25,25,0.88) 94%, rgba(36,36,36,0.88) 100%);
    background: linear-gradient(to bottom, rgba(76,76,76,0) 0%, rgba(89,89,89,0) 12%, rgba(102,102,102,0) 25%, rgba(71,71,71,0) 39%, rgba(44,44,44,0) 50%, rgba(0,0,0,0) 51%, rgba(17,17,17,0) 60%, rgba(19,19,19,0) 61%, rgba(43,43,43,0.64) 76%, rgba(40,40,40,0.77) 79%, rgba(28,28,28,0.86) 91%, rgba(26,26,26,0.88) 93%, rgba(25,25,25,0.88) 94%, rgba(36,36,36,0.88) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#4c4c4c', endColorstr='#242424', GradientType=0 );
}

.overProjectCoverImage .title{
    position: absolute;
    bottom: 0;
    width: 100%;
}

.overProjectCoverImage .title .architect-image{
    height: 55px;
}

.overProjectCoverImage .title .project-name{
    font-size: 1.2rem;
    text-transform: capitalize;
    font-style: italic;
}

.overProjectCoverImage .title .architect-name{
    font-size: 0.8rem;
}

.architetto-sidebar-block .block-container{
    margin-top: 30px
}

.architetto-sidebar-block .profile-pic-container{
    width: calc(100% - 30px);
    position: absolute;
    bottom: 15px;
    text-align: center;
}

.progetto-sidebar-block .block-container{
    margin-top: 20px;
}

.progetto-sidebar-block .profile-pic-container{
    width: 100%;
    bottom: 15px;
    text-align: center;
}

.progetto-sidebar-block .architect-name {
    color: #666666;
}

.card-progetto{
    color: #444444;
}

.card-progetto .card-text{
    text-align: justify;
}

.project-dettagli .label {
    display: inline-block;
    color: #666666;
    font-weight: bold;
}

.project-main-title{
    line-height: 48px;
    font-size: 0.7rem;
    background-color: #F0F0F0;
    color: #444444;
    letter-spacing: 3px;
    text-transform: uppercase;
}

.architetto-sidebar-block .profile-pic-container .profile-pic,
.progetto-sidebar-block .profile-pic-container .profile-pic{
    border: 3px Solid white;
    max-width: 200px;
    border-radius: 50%;
}

@media (max-width: 767.98px){
    .coverContent .nome-architetto-container{
        margin-top: 40px;
        text-align: center;
    }
}

@media (min-width: 768px) {
    .coverContent .nome-architetto-container{
        margin-top: 250px;
    }
}


.coverContent .tipologia{
    text-transform: uppercase;
}
.coverContent .nome-architetto{
    font-size: 2.5rem;
}

.profile-image-container{
    padding-top: 160px;
}

.profile-name-container{
    padding-top: 210px;

}

.profile-image{
    border: 7px Solid #FFFFFF;
    width: 86%;
}

.btn-hero{
    border-color: currentColor;
    border-style: solid;
    border-width: 1px;
    color: #888888;
    background-color: transparent;
    border-color: #888888;
}

.btn-outline-imd-secondary{
    color: #888888;
    background-color: transparent;
    border: 1px Solid #888888;
    border-radius: unset;
    width: available;
    width: -moz-available;
    width: -webkit-fill-available;
}

.bmd-form-group {
    position: relative;
    padding-top: 0;
}

.profile-stamp{
    height: 5rem;
}

.profile-address-icon {
    color: #666666;
}

.profile-address {
    text-align: left;
    font-size: 0.85rem;
    color: #666666;
}

.profile-form {
    text-align: left;
    font-size: 1rem;
    color: #666666;
}

.profile-menu a{
    color: #CCCCCC;
}

.nav-pills .nav-link.active{
    color: #FFFFFF;
}

.profile-menu a:hover{
    color: #FFFFFF;
}

.profile-box-esperienza{
    background-color: #e2e2e2;
    color: #444444;
    padding: 1rem;
}

.profile-box-esperienza hr{
    color: #444444;
}

.profile-box-progetti .titolo{
    font-weight: bold;
    display: inline-block;
    margin-top: 10px;
}

.profile-box-progetti .localita{
    font-style: italic;
    font-size: 0.8rem;
    color: #444444;
}

.titoli-sezioni{
    background-color: #e2e2e2;
    color: #444444;
    padding: 1rem;
}

.pannello-home{
    padding: 0.75rem;
    background-color: #e2e2e2;
}


.top-selection-block{
    background-color: #F3F3F3;
    box-shadow: none;
}

.simple-block, .simple-progetto-block{
    box-shadow: none;
    border: 1px solid rgba(0,0,0,0.12);
    border-radius: 1px;
}

.top-selection-block .tipologia{
    font-size: 12px;
    text-transform: uppercase;
    color: #666666;
    letter-spacing: ;
}

.simple-block .tipologia{
    font-size: 0.6rem;
    text-transform: uppercase;
    color: #999999;
    letter-spacing: 0.2rem;
}

.simple-block .header-tipologia{
    padding: 0.7rem;
}

.simple-block .profilo{
    height: 5rem;
    max-width: 100%
}

.simple-block .card-header .score{
    top: 73px;
    position: absolute;
    right: 3px;
    border: none;
    width: 1.8rem;
    height: 1.8rem;
}

.top-selection-block .nome-architetto{
    font-size: 1.5rem;
    line-height: 1.7rem;
    font-weight: 400;
    color: #666666;
    display: inline-block;
    margin-top: 0.4rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
}

.simple-block .nome-architetto{
    font-size: 1rem;
    font-weight: 400;
    color: #333333;
    display: inline-block;
    margin-top: 5px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-transform: uppercase;
    width: 100%;
}

.top-selection-block-link:hover{
    text-decoration: none;
}

.top-selection-block .badge{
    position: absolute;
    top: 0px;
    right: 0px;
    border: none;
    color: white;
    background-color: #287240;
    font-size: 10px;
    padding: 8px;
}

.top-selection-block figure{
    margin: 0;
}

.top-selection-block .figure-caption{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.simple-progetto-block .card-text{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-transform: capitalize;
    width: 100%;
    color: #666666;
}

.simple-progetto-block .card-body{
    border-top: 1px Solid rgba(0,0,0,0.12);
    padding: 0.9rem;
}

.site-footer{
    color: #999999;
    background-color: #EEEEEE;
    font-size: 0.7rem;
    padding: 2rem;
    margin-top: 2rem;
}

#imiHomeSlide .slide1 .question,
#imiHomeSlide .slide2 .question,
#imiHomeSlide .slide0 .question{
    color: white;
    font-size: 1.7rem;
    font-weight: 400;
    padding: 2px;
}

#imiHomeSlide .slide1 .cta,
#imiHomeSlide .slide2 .cta,
#imiHomeSlide .slide0 .cta{
    color: white;
    font-size: 1.2rem;
    font-weight: 400;
    padding: 2px;
}

.top-header a.login-link,
.top-header a.login-link:visited{
    color: green;
    font-weight: bold;
}

.single-architetto .architetto-sidebar-block{
    font-size: 0.8rem;
    position: relative;
}

.single-architetto .architetto-sidebar-block h5{
    text-transform: uppercase;
    font-weight: bold;
}

.single-architetto .architetto-sidebar-block .profile-address{
    font-size: 0.8rem;
}

.single-architetto-titolo,
.single-architetto-servizi,
.single-architetto-progetti{
    /* border-bottom: 1px solid #EEEEEE; */
}

.single-architetto-servizi .titoletti,
.single-architetto-progetti .titoletti,
.single-architetto-formazione .titoletti{
    font-size: 0.9rem;
    line-height: 0.9rem;
    font-weight: 500;
    color: #444444;
    text-transform: uppercase;
    background-color: #F0F0F0;
    padding: 10px;
}

.single-architetto-formazione .nav-tabs-highlight .nav-link.active {
    border-top-color: #888888;
}

.single-architetto-formazione .formation-title{
    width: 70%;
}

.single-architetto-formazione .formation-date{
    width: 15%;
}

.single-architetto-servizi .dettagli{
    font-size: 0.8rem;
    line-height: 1.28rem;
    color: #444444;
}

.single-architetto-titolo .tipologia{
    font-size: 0.6rem;
    text-transform: uppercase;
    color: #666666;
}

.single-architetto-titolo .nome-architetto{
    font-size: 1.7rem;
    line-height: 1.7rem;
    font-weight: 400;
    color: #444444;
    display: inline-block;
    margin-top: 0.4rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
}

.single-architetto-titolo .collaborazioni{
    line-height: 1.6rem;
}

.single-architetto-titolo .collaborazioni .icon-checkmark-circle2{
    vertical-align: middle;
    font-size: 1rem;
    font-weight: bold;
    color: #3d8b40;
}

.single-architetto-servizi .dettagli{
    vertical-align: middle;
    line-height: 1.2rem;
}

.single-architetto-servizi .dettagli .material-icons{
    vertical-align: middle;
    font-size: 0.5rem;
    font-weight: 400;
    color: #444444;
}

.single-architetto-progetti .photo-container{
    height: 300px;
}

.single-architetto .website a,
.single-architetto .social-icons a{
    color: #666666;
    text-decoration: none;
    background-color: transparent;
}

.single-architetto .social-icons i{
    font-size: 1.3rem;
    padding: 0.1rem;
}

.progetti-card-group {
    box-shadow: none;
    border: 1px solid rgba(0,0,0,0.12);
    border-radius: 1px;
}

.formazione-card{
    background-color: #e2e2e2;
}

.formazione-card .titolo{
    line-height: 1.6rem;
    font-weight: 500;
}

.formazione-card .categoria{
    line-height: 1rem;
    font-size: 0.8rem;
    font-weight: lighter;
}

.no-shadow{
    box-shadow: none;
    border-width: 1px;
}

.nav-tabs-solid[class*=bg-]:not(.bg-light):not(.bg-white):not(.bg-transparent) .nav-item.show .nav-link.active, .nav-tabs-solid[class*=bg-]:not(.bg-light):not(.bg-white):not(.bg-transparent) .nav-link.active {
    color: #fff;
    background-color: rgba(0,0,0,0.5);
}

.architect-search-button-box,
.project-search-button-box{
    position: sticky;
    bottom: 0;
    background-color: #f3f3f3;
}

.pagerfanta {
}

.pagerfanta a,
.pagerfanta span {
    display: inline-block;
    border: 1px solid rgba(0,0,0,0.12);
    color: #999;
    margin-right: .2em;
    padding: .35em .35em;
    min-width: 25px;
}

.pagerfanta a {
    text-decoration: none;
}

.pagerfanta a:hover {
    background: #ccc;
}

.pagerfanta .dots {
    border-width: 0;
}

.pagerfanta .active span{
    background: #ccc;
    font-weight: bold;
    color: white;
}

.pagerfanta .disabled {
    border-color: #ccc;
    color: #ccc;
}

.pagerfanta .pagination{
    display: -webkit-inline-box;
}

.search_filter {
    font-size: 12px;
    padding: 10px;
    margin-right: 5px;
}

